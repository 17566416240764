body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eefcf7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
    margin: 2% 7% 5% 5%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: 'Overpass', sans-serif;
}

.navbar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  background-color: #a7d1c2;
  padding: 20px 35px 25px 35px;
  color: white;
  position: sticky;
  top: 0;
}

.navLinks a {
  padding: 0 10px 0 10px; 
  font-weight: 400;
  font-size: 1.5em;
  text-decoration: none;
}

.navLinks a:hover {
  cursor: pointer;
  color: gray;
}

.navLinks a:visited {
  color: white;
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
}

.icons a {
  padding: 0 10px 10px 10px;
}

.nameHome {
  font-size: 4em;
}

.profilePic {
  height: 30em;
  width: auto;
  border-radius: 100%;
  margin-right: 15px;
}

.aboutMe {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.aboutHeader {
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.bio {
  width: 15em;
  margin: 5px 0px 5px 8px;
}

p {
  font-size: 1em;
}

span {
  font-size: 1.5em;
  padding-left: 100px;
}

h1 {
  font-weight: 600;
}

h2 {
  font-size: 2em;
  padding-left: 100px;
}

h3 {
  font-size: 1.5em;
  padding-left: 100px;
}

.examples {
  display: flex;
  flex-direction: column;
}

.projectSum {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.projectSum span {
  padding-left: 0px;
}

.examples img {
  width: 15em;
}

.examples p {
  width: 10em;
}

@media (min-width: 800px){
  .navbar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    background-color: #a7d1c2;
    padding: 20px 35px 25px 35px;
    color: white;
    position: sticky;
    top: 0;
  }
  
  .navLinks a {
    padding: 0 10px 0 10px; 
    font-weight: 400;
    font-size: 2em;
    color: white;
    text-decoration: none;
  }
  
  .navLinks a:hover {
    cursor: pointer;
    color: gray;
  }
  
  .navLinks a:visited {
    color: white;
  }
  
  .icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 20px;
  }
  
  .icons a {
    padding: 0 10px 10px 10px;
  }
  
  .nameHome {
    font-size: 4em;
  }
  
  .profilePic {
    height: 30em;
    width: auto;
    border-radius: 100%;
    margin-right: 30px;
  }
  
  .aboutMe {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .aboutHeader {
    text-align: left;
    padding-bottom: 0;
  }
  
  .bio {
    width: 60em;
    margin: 20px 0px 20px 70px;
  }
  
  p {
    font-size: 2em;
  }
  
  span {
    font-size: 1.8em;
    padding-left: 100px;
  }
  
  h1 {
    font-weight: 600;
  }
  
  h2 {
    font-size: 4em;
    padding-left: 100px;
  }
  
  h3 {
    font-size: 3.7em;
    padding-left: 100px;
  }
  
  .examples {
    display: flex;
    flex-direction: column;
  }
  
  .projectSum {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .projectSum span {
    padding-left: 0px;
  }
  
  .examples img {
    width: 34em;
  }
  
  .examples p {
    width: 16em;
  }
}

